import { useEffect , useState } from "react";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Home/Hero";
import ListDomains from "./components/Home/ListDomains";
import SearchBox from "./components/Home/SearchBox";
import NavBar from "./components/NavBar";
import Modal from "./components/Modal";


function App() {
  const [wishlist, setWishList] = useState(localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [])
  const [offerFor , setOfferFor] = useState({})
  const [showOfferModal , setShowOfferModal] = useState(false)
  
  return (
    <>
      <NavBar wishlist={wishlist}/>
      <Hero/>
      <ListDomains wishlist={wishlist} setWishList={setWishList} setShowOfferModal={setShowOfferModal} setOfferFor={setOfferFor} />
      <Contact/>
      {showOfferModal && <Modal offerFor={offerFor} setShowOfferModal={setShowOfferModal} />}
      <Footer/>
    </>

  );
}

export default App;

import React, {useState} from 'react'
import Navbar from './components/NavBar'
import Contact from './components/Contact'
import Footer from './components/Footer'

const ContactPage = () => {
    const [wishlist, setWishList] = useState(localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [])

  return (
    <>
        <Navbar wishlist={wishlist}/>
        <div className='mt-24'>
            <Contact/>
        </div>
        <Footer/>
    </>
  )
}

export default ContactPage
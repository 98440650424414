import React, { useState } from 'react'
import { domains } from '../../Data/Domains'

const Body = ({ wishlist, setWishList , setShowOfferModal , setOfferFor}) => {
    const [selectedDomains, setSelectedDomains] = useState(domains)

    const [filtering, setFiltering] = useState({ extension: "", category: "", name: "" })

    const addToWishlist = (elt) => {
        let wishlist = JSON.parse(localStorage.getItem('wishlist')) || []
        const index = wishlist.findIndex(domain => domain.id === elt.id)

        if (index === -1) {
            wishlist.push(elt)
        } else {
            wishlist.splice(index, 1)
        }

        localStorage.setItem('wishlist', JSON.stringify(wishlist))
        setWishList(wishlist)
    }

    const changeFiltering = () => {
        let filteredDomains = domains

        if (filtering.extension) {
            filteredDomains = filteredDomains.filter(domain => domain.name.endsWith(filtering.extension))
        }

        if (filtering.category) {
            filteredDomains = filteredDomains.filter(domain => domain.category === filtering.category)
        }

        if (filtering.name) {
            filteredDomains = filteredDomains.filter(domain => domain.name.toLowerCase().includes(filtering.name.toLowerCase()))
        }

        setSelectedDomains(filteredDomains)
    }

    return (
        <div className='w-screen'>
            <div className='w-[92%] xl:w-[86%] min-h-[100vh] mx-auto mt-32 flex justify-between flex-col md:flex-row gap-7 '>
                <div className='grid md:block gap-4 grid-cols-1'>

                    <div className="w-[100%] md:w-[300px] mx-auto mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Extension</label>
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2.5 " value={filtering.extension} onChange={(e) => {
                            setFiltering({ ...filtering, extension: e.target.value })
                        }}>
                            <option value="">All</option>
                            <option value=".co">.co</option>
                            <option value=".com">.com</option>
                            <option value=".net">.net</option>
                            <option value=".ai">.ai</option>
                        </select>
                    </div>
                    <div className=" w-[100%] md:w-[300px] mx-auto">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2.5 " value={filtering.category} onChange={(e) => {
                            setFiltering({ ...filtering, category: e.target.value })
                        }}>
                            <option value="">All</option>
                        </select>
                    </div>

                    <button className='mt-6 rounded-lg bg-[#f66e3c] text-white p-2 px-2 text-base font-semibold sm:px-4 ' onClick={changeFiltering}>
                        Filter
                    </button>

                </div>
                <div className="w-[100%]">

                    <div className="w-[100%] mb-8">
                        <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm  text-gray-900 border outline-none border-gray-300 rounded-lg bg-gray-50  " placeholder="Search keywords & domain names" value={filtering.name} onChange={(e) => { setFiltering({ ...filtering, name: e.target.value }) }} />
                            <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-[#f66e3c] hover:bg-[#f66e3c]  font-medium rounded-lg text-sm px-4 py-2 " onClick={changeFiltering}>Search</button>
                        </div>
                    </div>

                    {selectedDomains.map((elt) => <div key={elt.id} className='w-[100%]shadow-lg py-3 px-4 border  rounded-lg  mb-3'>
                        <h2 className='font-semibold text-2xl lg:text-3xl'>{elt.name}</h2>
                        <div className='flex justify-between items-center mt-4'>
                            <button className='py-2 px-4 border border-[#28a745] text-[#28a745] hover:bg-[#28a745] hover:text-white transition-all duration-300 ease-in-out font-medium text-base rounded-md' onClick={() => {
                                setShowOfferModal(true)
                                setOfferFor(elt)
                            }}>Make offer</button>
                            <button onClick={() => addToWishlist(elt)}> {wishlist.findIndex(domain => domain.id === elt.id) === -1 ? <i className="fa-regular fa-heart hover:text-red-500 text-lg"></i> : <i className="fa-solid fa-heart text-red-500 text-lg"></i>}</button>
                        </div>
                    </div>)}

                </div>

            </div>
        </div>
    )
}

export default Body

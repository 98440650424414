import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'

const Modal = ({ offerFor, setShowOfferModal }) => {

    const form = useRef();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')
    const [clickSend, setClickSend] = useState(false)
    const [offre , setOffre] = useState('')


    const sendEmail = (e) => {
        e.preventDefault()
        setClickSend(true)
        if (!firstName) {
            setClickSend(false)
            return Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Prénom vide !",
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (!lastName) {
            setClickSend(false)
            return Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Nom vide !",
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (!email) {
            setClickSend(false)
            return Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Email vide !",
                showConfirmButton: false,
                timer: 1500
            })
        }
        emailjs
            .sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, form.current, {
                publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
            })
            .then(
                () => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: '',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => window.location.reload())
                },
                (error) => {
                
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: error,
                        showConfirmButton: false,
                        timer: 1500
                    })
                },
            );
    }
    return (
        <>



            <div id="default-modal" tabindex="-1" aria-hidden="true" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  flex justify-center items-center w-full md:inset-0 h-[100vh] max-h-full bg-black/50">
                <div className="relative p-4 w-full max-w-2xl max-h-full">

                    <div className="relative bg-white rounded-lg shadow ">

                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Make offer
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal" onClick={() => setShowOfferModal(false)}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <main className="flex items-center flex-col gap-8 text-dark py-8 px-4 w-full ">

                            <p className='font-semibold text-base'>Fill out your offer for <span className='font-bold underline'>{offerFor?.name}</span> below, to get in touch with the owner of this domain name.</p>
                            <div className="mx-auto w-full ">
                                <form ref={form} onSubmit={sendEmail} className=' w-full h-auto '>
                                    <input type="hidden" name='offreFor' value={offerFor?.name} />
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3 mb-6 ">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light text-xs font-bold mb-2" for="grid-first-name">
                                                First Name
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Sam" name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div className="w-full  px-3 ">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light  text-xs font-bold mb-2" for="grid-last-name">
                                                Last Name
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 borde border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Richards" name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3 mb-6 ">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light text-xs font-bold mb-2" for="grid-first-name">
                                                Email
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Sam@gmail.com" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="w-full px-3 mb-6">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light  text-xs font-bold mb-2" for="grid-last-name">
                                                Phone number
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 borde border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Phone number" name='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                        <div className="w-full px-3 mb-6">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light  text-xs font-bold mb-2" for="grid-last-name">
                                                Offer
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 borde border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder={offerFor.offer} name='offre' value={offre} onChange={(e) => setOffre(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-2 ">
                                        <div className="w-full  px-3  mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 dark:text-light text-xs font-bold mb-2" for="grid-first-name">
                                                Message
                                            </label>
                                            <textarea className="appearance-none block w-full bg-gray-200 text-gray-700  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" placeholder="Message" value={message} name='message' onChange={(e) => setMessage(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div className='w-full mt- flex justify-center items-center'>
                                        <button className='ml-4 rounded-lg bg-[#f66e3c] text-white p-2 px-2 text-base font-semibold sm:px-4 sm:text-lg flex gap-4' disabled={clickSend}>
                                            {clickSend && <svg aria-hidden="true" className="w-8 h-8 text-[#FA96DF]  animate-spin  fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>}                                            Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </main>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Modal
import React , {useState} from 'react'
import Navbar from './components/NavBar'
import Contact from './components/Contact'
import Footer from './components/Footer'
import About from './components/About/About'

const AboutPage = () => {
    const [wishlist, setWishList] = useState(localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [])

  return (
    <>
        <Navbar wishlist={wishlist}/>
        <About/>
        <Contact/>
        <Footer/>
    </>
  )
}

export default AboutPage
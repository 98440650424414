import React from 'react'

const Hero = () => {
    return (
        <div className='w-screen h-[90vh] hero'>
            <div className='w-full h-full bg-black/20'>
                <div className='w-[92%] xl:w-[86%] h-[100vh] mx-auto flex justify-center items-center'>
                    <div className='max-w-[800px] h-fit text-center text-white'>
                        <h2 className='font-bold text-2xl md:text-5xl'>NameConvoy Domain Marketplace</h2>
                        <p className='font-medium text-md md:text-2xl mt-6'>Your domain is more than just an address - it's an unparalleled symbol of worldwide credibility, recognition and trust. Secure your unique name today, before it's gone forever.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
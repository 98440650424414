import React, { useEffect, useState } from 'react';

const List = ({ wishlist, setWishList, setShowOfferModal, setOfferFor }) => {

    const removeFromWishlist = (elt) => {
        const newWishlist = wishlist.filter(domain => domain.id !== elt.id);
        localStorage.setItem('wishlist', JSON.stringify(newWishlist));
        setWishList(newWishlist);
    };

    return (
        <div className='w-screen my-20'>
            <div className='w-[92%] xl:w-[86%] mx-auto'>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-16'>
                    {wishlist.map((elt) => (
                        <div key={elt.id} className='shadow-lg py-5 px-7 border rounded-lg hover:scale-105 transition-all duration-300 ease-in-out'>
                            <h2 className='font-semibold text-2xl lg:text-3xl'>{elt.name}</h2>
                            <div className='flex justify-between items-center mt-10'>
                                <button className='py-2 px-4 border border-[#28a745] text-[#28a745] hover:bg-[#28a745] hover:text-white transition-all duration-300 ease-in-out font-medium text-base rounded-md' onClick={() => {
                                    setShowOfferModal(true)
                                    setOfferFor(elt)
                                }}>Make offer</button>
                                <button onClick={() => removeFromWishlist(elt)}><i className="fa-solid fa-heart text-red-500 text-lg"></i></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default List;

import React from 'react'

const Footer = () => {
  return (
    <div className='w-screen bg-black'>
        <div className='w-[92%] xl:w-[86%]  mx-auto py-8 '>
            <p className='text-center text-white text-sm md:text-lg'>2024 © Archblue Limited</p>
        </div>
    </div>
  )
}

export default Footer
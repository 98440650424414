import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Routes, Route, HashRouter } from "react-router-dom";
import ContactPage from "./ContactPage";
import AboutPage from "./AboutPage";
import DomainsPage from "./DomainsPage";
import FavoritePage from "./FavoritePage";
import { inject } from "@vercel/analytics";

inject();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HashRouter basename="/">
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/domains" element={<DomainsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/favorite" element={<FavoritePage />} />
    </Routes>
  </HashRouter>
);

import React , {useState} from 'react'
import Navbar from './components/NavBar'
import Footer from './components/Footer'
import Body from './components/Domains/Body'
import Modal from "./components/Modal";


const DomainsPage = () => {
    const [wishlist, setWishList] = useState(localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [])
    const [offerFor , setOfferFor] = useState({})
    const [showOfferModal , setShowOfferModal] = useState(false)
  return (
    <>
        <Navbar wishlist={wishlist}/>
        <Body wishlist={wishlist} setWishList={setWishList} setShowOfferModal={setShowOfferModal} setOfferFor={setOfferFor} />
        {showOfferModal && <Modal offerFor={offerFor} setShowOfferModal={setShowOfferModal} />}
        <Footer/>
    </>
  )
}

export default DomainsPage
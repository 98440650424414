import React from 'react'

const About = () => {
  return (
    <div className='w-screen'>
        <div className='w-[92%] xl:w-[86%]  mx-auto mt-32 mb-10 flex justify-between gap-5 flex-col lg:flex-row' >
            <div>
                <h2 className='font-bold text-2xl md:text-5xl'>About NameConvoy</h2>
                <p className='font-medium text-md mt-6'>NameConvoy is a premium domain seller that specialises in supporting brands and start-ups to acquire their ideal domain name. We can offer direct domain rental or lease-to-own options. Don't wait. Contact us today!</p>
            </div>
            <div className='max-w-[500px]'>
                <img src="./background.jpg" alt="" />
            </div>
        </div>
    </div>
  )
}

export default About
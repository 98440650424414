import React, {useState} from 'react'
import { domains } from '../../Data/Domains'
import SearchBox from './SearchBox'

const ListDomains = ({wishlist , setWishList , setShowOfferModal , setOfferFor}) => {

    const [searchFor , setSearchFor] = useState('')
    const [selectedDomains , setSelectedDomains ] = useState(domains)

    const addToWishlist = (elt) => {
        let wishlist = JSON.parse(localStorage.getItem('wishlist')) || []
        const index = wishlist.findIndex(domain => domain.id === elt.id)

        if (index === -1) {
            wishlist.push(elt)
        } else {
            wishlist.splice(index, 1)
        }

        localStorage.setItem('wishlist', JSON.stringify(wishlist))
        setWishList(wishlist)
    }

    const filterElement = () => {
        if(searchFor)
            setSelectedDomains(domains.filter(elt => elt.name.toLowerCase().includes(searchFor.toLocaleLowerCase())))
        else 
            setSelectedDomains(domains)
       
    }
    return (
       <>
       <SearchBox searchFor={searchFor} setSearchFor={setSearchFor} filterElement={filterElement} />
         <div className='w-screen my-20'>
            <div className='w-[92%] xl:w-[86%]  mx-auto'>
                <h1 className='font-bold text-xl sm:text-2xl lg:text-4xl uppercase text-gray-400'>PREMIUM DOMAINS</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-16'>
                    {selectedDomains.map((elt ) => <div key={elt.id} className='shadow-lg py-5 px-7 border  rounded-lg hover:scale-105 transition-all duration-300 ease-in-out'>
                        <h2 className='font-semibold text-2xl lg:text-3xl'>{elt.name}</h2>
                        <div className='flex justify-between items-center mt-10'>
                            <button className='py-2 px-4 border border-[#28a745] text-[#28a745] hover:bg-[#28a745] hover:text-white transition-all duration-300 ease-in-out font-medium text-base rounded-md' onClick={() => { setShowOfferModal(true)
                                setOfferFor(elt)
                            }}>Make offer</button>
                            <button onClick={() => addToWishlist(elt)}> {wishlist.findIndex(domain => domain.id === elt.id) === -1 ? <i className="fa-regular fa-heart hover:text-red-500 text-lg"></i> : <i className="fa-solid fa-heart text-red-500 text-lg"></i> }</button>
                        </div>
                    </div>)}
                </div>
            </div>

        </div>
       </>
    )
}

export default ListDomains
import React from 'react'

const SearchBox = ({searchFor, setSearchFor ,filterElement }) => {
    return (
        <div className='w-screen mt-[-80px]'>
            <div className='w-[92%] xl:w-[86%]  mx-auto shadow-lg rounded-xl bg-white py-10' >

                <div className="w-[90%] md:w-[70%] mx-auto">
                    <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm sm:text-md md:text-lg text-gray-900 border outline-none border-gray-300 rounded-lg bg-gray-50  " placeholder="Search keywords & domain names" value={searchFor} onChange={(e) => setSearchFor(e.target.value)} />
                        <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-[#f66e3c] hover:bg-[#f66e3c]  font-medium rounded-lg text-sm sm:text-md md:text-lg px-4 py-2 " onClick={filterElement}>Search</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SearchBox
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Favorite = () => {
    const [wishlist, setWishList] = useState(localStorage.wishlist ? JSON.parse(localStorage.wishlist) : [])
   
    return (
        <div className='w-screen mt-24 h-[50vh] hero'>
            <div className='w-[92%] xl:w-[86%] mx-auto flex justify-center items-center flex-col h-full'>
                <h2 className='font-bold text-2xl md:text-5xl text-white'>Withlist</h2>
                {wishlist.length <= 0 && <p className='font-medium text-md md:text-2xl mt-6 text-white'>No domains on your shortlist yet!</p>}
                <Link to={'/domains'}><button className='mt-6 rounded-lg bg-[#f66e3c] text-white p-2 px-2 text-base font-semibold sm:px-4 '>
                    Browse domains
                </button></Link>
            </div>
        </div>
    )
}

export default Favorite
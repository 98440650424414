import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';



const Navbar = ({wishlist}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [openAboutMenu, setOpenAboutMenu] = useState(false)
    const aboutMenuRef = useRef(null)
    const mobileMenuRef = useRef(null)
    const menuBtn = useRef(null)
    const location = useLocation()
    const { pathname } = location

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (aboutMenuRef.current && !aboutMenuRef.current.contains(event.target)) {
                setOpenAboutMenu(false)
            }

            if (
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                menuBtn.current &&
                !menuBtn.current.contains(event.target) &&
                aboutMenuRef.current &&
                !aboutMenuRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        };

        window.addEventListener('click', handleClickOutside)
        return () => {
            window.removeEventListener('click', handleClickOutside)
        };
    }, []);


   

    return (
        <nav className='w-screen py-10 shadow-lg fixed top-0 bg-white z-50'>
            <div className='w-[92%] xl:w-[86%] mx-auto flex justify-between items-center'>
                <a href={`#${pathname}`} className='font-bold text-xl uppercase'>Nameconvoy</a>
           
                <button aria-label="menu button" ref={menuBtn} type='button' className='block lg:hidden flex-col justify-center items-center' onClick={() => setIsOpen(!isOpen)}>
                    <span className={` transition-all duration-300 ease-out bg-black block h-0.5 w-6 rounded-sm ${isOpen ? 'rotate-45 translate-y-1' : '-translate-y-0.5'} `}></span>
                    <span className={` transition-all duration-300 ease-out bg-black block h-0.5 w-6 rounded-sm my-0.5 ${isOpen ? 'opacity-0' : 'opacity-100'} `}></span>
                    <span className={` transition-all duration-300 ease-out bg-black block h-0.5 w-6 rounded-sm  ${isOpen ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'} `}></span>
                </button>
                <ul className='text-black hidden lg:flex justify-between font-semibold items-end gap-12'>

                    <li className='uppercase '><HashLink to="/">Home</HashLink></li>
                    <li className='uppercase '><HashLink to="/domains">Domains</HashLink></li>
                    <li className='uppercase'><HashLink to="/about">About us</HashLink></li>
                    <li className='uppercase'><HashLink to="/contact">Contact</HashLink></li>
                    <li className='uppercase relative'><HashLink to="/favorite"><i className="fa-regular fa-heart"></i><span className='absolute -top-1 -right-4 !w-[20px] !h-[20px]  inline-flex justify-center items-center bg-red-500 rounded-full text-xs  text-white'>{wishlist.length}</span></HashLink></li>
                </ul>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            ref={mobileMenuRef}
                            initial={{ scale: 0, opacity: 0, x: '-50%', y: '-50%' }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            className='w-[90%] sm:w-[70vw] flex flex-col justify-between z-30 items-center fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#000000]/75 rounded-xl backdrop-blur-md py-32 shadow-2xl'
                        >
                            <ul className='text-white flex flex-col items-center justify-center gap-8'>
                                <li className='uppercase ' onClick={() => setIsOpen(false)}><HashLink to="/">Home</HashLink></li>
                                <li className='uppercase ' onClick={() => setIsOpen(false)}><HashLink to="/domains">Domains</HashLink></li>
                                <li className='uppercase' onClick={() => setIsOpen(false)}><HashLink to="/about">About us</HashLink></li>
                                <li className='uppercase' onClick={() => setIsOpen(false)}><HashLink to="/contact">Contact</HashLink></li>
                                <li className='uppercase ' onClick={() => setIsOpen(false)}><HashLink to="/favorite"><i className="fa-regular fa-heart"></i></HashLink></li>
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </nav>
    );
};

export default Navbar
